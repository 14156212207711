import { useContext } from 'react';
import { Box, styled } from '@mui/material';

import { DashboardContext } from 'context/DashboardContext';

import BonusOptions from './BonusOptions';
import {
  ApedCard,
  DiamondBonusCard,
  EarlyDegenCard,
  ReferralBonusCard,
  SpreadTheWordCard,
  PowerTweetCard,
} from 'components/PromotionCard';
import ZealyCard from 'components/PromotionCard/cards/ZealyCard';
import { BoostersBox } from '.';

const bonusesMap = {
  apedIn: ApedCard,
  earlyDegen: EarlyDegenCard,
  referral: ReferralBonusCard,
  spreadTheWord: SpreadTheWordCard,
  zealy: ZealyCard,
  diamondHands: DiamondBonusCard,
  powerTweet1: PowerTweetCard,
  powerTweet2: PowerTweetCard,
  powerTweet3: PowerTweetCard,
  powerTweet4: PowerTweetCard,
};

const Container = styled(Box)(({ theme }) => ({
  padding: theme.spacing(0, 2),
  marginTop: theme.spacing(3),

  '@media (min-width: 600px)': {
    padding: theme.spacing(0, 3),
  },
}));

const Heading = styled(Box)(() => ({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
}));

const BoostersContainer = styled(Box)(({ theme }) => ({
  display: 'grid',
  gridTemplateColumns: 'repeat(auto-fit, minmax(320px, 1fr))',
  width: '100%',
  gap: theme.spacing(3),
  marginTop: theme.spacing(3),
}));

const Bonus = () => {
  const { state, getBonusesByType, refetchData } = useContext(DashboardContext);

  const bonuses = getBonusesByType(state.bonusType);
  const boosters = bonuses.reduce((acc, value) => acc + value.multiplier * 100 - 100, 0);

  return (
    <Container>
      <Heading>
        <BonusOptions />
      </Heading>
      <BoostersContainer>
        <BoostersBox boosters={boosters} />
        {bonuses.map((bonus) => {
          const Card = bonusesMap[bonus.name];
          return Card ? <Card {...bonus} key={bonus.name} refetchData={refetchData} /> : null;
        })}
      </BoostersContainer>
    </Container>
  );
};

export default Bonus;
