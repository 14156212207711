import { styled, Box } from '@mui/material';

import ReferralBonusCard from './ReferralBonusCard';
import EarlyDegenCard from './EarlyDegenCard';
import ApedCard from './ApedCard';
import SpreadTheWordCard from './SpreadTheWordCard';
import DiamondBonusCard from './DiamondBonusCard';
import PowerTweetCard from './PowerTweetCard';

const Container = styled(Box)(() => ({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-between',
  height: '100%',
}));

const FlexBox = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'flex-start',
  justifyContent: 'space-between',
  paddingTop: theme.spacing(0.5),
}));

export {
  ReferralBonusCard,
  EarlyDegenCard,
  ApedCard,
  SpreadTheWordCard,
  DiamondBonusCard,
  PowerTweetCard,
  Container,
  FlexBox,
};
