import { Box, Typography, styled } from '@mui/material';

import { MAXIMUM_BOOSTER_PERCENTAGE } from 'constants';

const Container = styled(Box)(({ theme }) => ({
  position: 'relative',
  background: 'linear-gradient(to bottom right, #39FF14, #00FFFF, #FFFF00)',
  padding: theme.spacing(0.5),
  borderRadius: theme.spacing(1),
}));

const Content = styled(Box)(({ theme }) => ({
  background: theme.palette.background.filter,
  padding: theme.spacing(2),
  borderRadius: theme.spacing(1),
  height: '100%',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-between',
}));

const ActivatedText = styled(Typography)(({ theme }) => ({
  fontSize: 22,
  color: '#39FF14',
  fontWeight: 600,
  textAlign: 'center',
  marginTop: theme.spacing(2),
}));

const PercentageText = styled(Typography)(({ theme }) => ({
  fontSize: 48,
  color: 'transparent',
  fontWeight: 600,
  textAlign: 'center',
  marginTop: theme.spacing(2),
  backgroundImage: 'linear-gradient(to bottom right, #39FF14 0%, #00FFFF 70%, #39FF14 100%)',
  backgroundClip: 'text',
}));

const Progress = styled(Box)(({ theme }) => ({
  height: 16,
  backgroundColor: 'rgba(57,255,20,0.2)',
  borderRadius: theme.spacing(1),
}));

const ProgressBar = styled(Box)(({ theme, percentage }) => ({
  width: percentage + '%' ?? 0,
  height: 16,
  backgroundColor: '#39FF14',
  borderRadius: theme.spacing(1),
}));

const BoostersBox = ({ boosters = 0 }) => {
  const percentage = Math.trunc((boosters / MAXIMUM_BOOSTER_PERCENTAGE) * 100);

  return (
    <Container>
      <Content>
        <Box>
          <ActivatedText>Boosters Activated</ActivatedText>
          <PercentageText>{boosters}%</PercentageText>
        </Box>
        <Progress>
          <ProgressBar percentage={percentage} />
        </Progress>
      </Content>
    </Container>
  );
};

export default BoostersBox;
