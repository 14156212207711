import React from 'react';
import { styled } from '@mui/material';
import { Text } from 'components/Text';

const Flex = styled('div')(({ theme }) => ({
  display: 'inline-flex',
  gap: theme.spacing(1),
  alignItems: 'center',
}));

const BonusAmountText = ({ value, upTo, reserved, gold = 0 }) => {
  return (
    <Flex>
      {upTo && (
        <Text fs={12} fw={600} md={{ fs: 12, fw: 500 }}>
          UP TO
        </Text>
      )}
      <Text fs={20} fw={600} md={{ fs: 28, fw: 500 }}>
        {value}%
      </Text>
      <Text fs={12} fw={500}>
        BONUS{reserved && <span> ACTIVATED</span>}
      </Text>
      {gold > 0 && (
        <Text fs={20} fw={600} md={{ fs: 28, fw: 500 }}>
          {' '}
          + {gold} Gold
        </Text>
      )}
    </Flex>
  );
};

export default BonusAmountText;
