import { createContext, useEffect, useReducer } from 'react';

import user from 'services/user';
import { useWallet } from './WalletContext';
import { CURRENT_REWARD_SEASON, AIRDROP_SEASON } from 'constants';

import {
  AetherGemIcon,
  AmethystsGemIcon,
  DiamondGemIcon,
  EmeraldGemIcon,
  GoldIcon,
  ManaGemIcon,
  PearlGemIcon,
  QuartzGemIcon,
  RubyGemIcon,
  SapphireGemIcon,
  TopazGemIcon,
} from 'components/ImageComponent';

const AIRDROPS = {
  powerTweet: {
    name: 'Power Tweet Airdrop',
    key: 'powerTweet',
    icon: GoldIcon,
    currency: 'Gold',
    S2: false,
  },
  volumeAirdrop: {
    name: 'Marketplace Vol Airdrop',
    key: 'volumeAirdrop',
    icon: DiamondGemIcon,
    currency: 'Diamonds',
  },
  tokenHodler: {
    name: 'Token Hodler Airdrop',
    key: 'tokenHodler',
    icon: AetherGemIcon,
    currency: 'Aether',
  },
  nftHodler: {
    name: 'NFT Hodler Airdrop',
    key: 'nftHodler',
    icon: RubyGemIcon,
    currency: 'Rubies',
  },
  liquidity: {
    name: 'Liquidity Airdrop',
    key: 'liquidity',
    icon: SapphireGemIcon,
    currency: 'Sapphires',
    rank: null,
    gmiAmount: null,
  },
  x: {
    name: 'X Airdrop',
    key: 'x',
    icon: AmethystsGemIcon,
    currency: 'Amethysts',
  },
  listing: {
    name: 'Listing Airdrop',
    key: 'listing',
    icon: ManaGemIcon,
    currency: 'Mana',
    S2: false,
  },
  portfolio: {
    name: 'Portfolio Value Airdrop',
    key: 'portfolio',
    icon: EmeraldGemIcon,
    currency: 'Emeralds',
    S2: false,
  },
  trader: {
    name: 'Trader Airdrop',
    key: 'trader',
    icon: TopazGemIcon,
    currency: 'Topaz',
  },
  creator: {
    name: 'Creator Airdrop',
    key: 'creator',
    icon: QuartzGemIcon,
    currency: 'Quartz',
  },
  zealy: {
    name: 'Zealy Airdrop',
    key: 'zealy',
    icon: PearlGemIcon,
    currency: 'Pearls',
  },
};

const initialState = {
  season: AIRDROP_SEASON,
  data: [],
  total: 0,
  loading: false,
  airdrops: Object.values(AIRDROPS),
  totalGMI: 0,
  top10Ranked: 0,
  currentRewardedTotalGMI: 0,
};

const ACTIONS = {
  SET_DATA: 'SET_DATA',
  SET_LOADING: 'SET_LOADING',
  SET_SEASON: 'SET_SEASON',
  SET_AIRDROPS: 'SET_AIRDROPS',
  SET_TOTAL_GMI: 'SET_TOTAL_GMI',
  SET_TOP_10_RANKED: 'SET_TOP_10_RANKED',
  SET_CURRENT_REWARD_TOTAL: 'SET_CURRENT_REWARD_TOTAL',
};

const RewardsVaultReducer = (state, action) => {
  switch (action.type) {
    case ACTIONS.SET_DATA: {
      return {
        ...state,
        data: action.payload,
      };
    }
    case ACTIONS.SET_LOADING: {
      return {
        ...state,
        loading: action.payload,
      };
    }
    case ACTIONS.SET_SEASON: {
      return {
        ...state,
        season: action.payload,
      };
    }
    case ACTIONS.SET_AIRDROPS: {
      return {
        ...state,
        airdrops: action.payload,
      };
    }
    case ACTIONS.SET_TOTAL_GMI: {
      return {
        ...state,
        totalGMI: action.payload,
      };
    }
    case ACTIONS.SET_TOP_10_RANKED: {
      return {
        ...state,
        top10Ranked: action.payload,
      };
    }
    case ACTIONS.SET_CURRENT_REWARD_TOTAL: {
      return {
        ...state,
        currentRewardedTotalGMI: action.payload,
      };
    }
  }

  throw Error('Unknown action: ' + action.type);
};

export const RewardsVaultContext = createContext(null);

export const RewardsVaultProvider = ({ children }) => {
  const [state, dispatch] = useReducer(RewardsVaultReducer, initialState);
  const { address, isAuthenticated } = useWallet();
  const season = state.season;

  const fetchRewards = async (season) => {
    setLoading(true);
    const data = await user.getUserRewards(season);

    const airdrops = Object.keys(AIRDROPS)
      .map((item) => ({
        ...data[item],
        ...AIRDROPS[item],
      }))
      .filter((item) => {
        if (season === 2) {
          return item.S2 !== false;
        }
        return item;
      });

    const totalGMI = parseInt(
      Object.values(airdrops).reduce((prev, current) => prev + (Number(current.gmiAmount) || 0), 0),
    );

    const top10Ranked = airdrops.filter((item) => item.rank && item.rank <= 10).length;

    setData(data);
    dispatch({ type: ACTIONS.SET_AIRDROPS, payload: airdrops });
    dispatch({ type: ACTIONS.SET_TOTAL_GMI, payload: totalGMI });
    dispatch({ type: ACTIONS.SET_TOP_10_RANKED, payload: top10Ranked });
    setLoading(false);
  };

  const fetchCurrentReward = async (season) => {
    const data = await user.getUserRewards(CURRENT_REWARD_SEASON);

    const airdrops = Object.keys(AIRDROPS)
      .map((item) => ({
        ...data[item],
        ...AIRDROPS[item],
      }))
      .filter((item) => {
        if (season === 2) {
          return item.S2 !== false;
        }
        return item;
      });

    const totalGMI = parseInt(
      Object.values(airdrops).reduce((prev, current) => prev + (Number(current.gmiAmount) || 0), 0),
    );

    dispatch({ type: ACTIONS.SET_CURRENT_REWARD_TOTAL, payload: totalGMI });
  };

  const setLoading = (loading) => {
    dispatch({ type: ACTIONS.SET_LOADING, payload: loading });
  };

  const setData = (data) => {
    dispatch({ type: ACTIONS.SET_DATA, payload: data });
  };

  const setSeason = (season) => {
    dispatch({ type: ACTIONS.SET_SEASON, payload: season });
  };

  useEffect(() => {
    if (address && isAuthenticated && season !== 'Season 1') {
      fetchRewards(season);
    }
  }, [season, address, isAuthenticated]);

  useEffect(() => {
    if (isAuthenticated) {
      fetchCurrentReward();
    }
  }, [isAuthenticated]);

  return (
    <RewardsVaultContext.Provider
      value={{
        state,
        dispatch,
        fetchRewards,
        setSeason,
      }}
    >
      {children}
    </RewardsVaultContext.Provider>
  );
};
