import { StatsApi } from 'clientSDK';

const statsApi = new StatsApi();

export default {
  getLeaderboard: async ({ skip = 0, limit = 10, includeGraphStats = true }) =>
    await statsApi.getLeaderboard({ skip, limit, includeGraphStats }),

  getAccountStats: async ({ address, skip = 0, limit = 10, includeGraphStats = true }) =>
    await statsApi.getAccountStats({ address, skip, limit, includeGraphStats }),

  searchForAddress: async (address) => await statsApi.searchForAddress(address),

  getS1Leaderboard: async () => await statsApi.getS1Leaderboard(),

  getTraderLeaderboard: async ({ skip, limit }) =>
    await statsApi.getTraderLeaderboard({ skip, limit }),

  getCreatorLeaderboard: async ({ skip, limit }) =>
    await statsApi.getCreatorLeaderboard({ skip, limit }),

  getXLeaderboard: async ({ skip, limit }) => await statsApi.getXLeaderboard({ skip, limit }),

  getTokenHodlerLeaderboard: async ({ skip, limit }) =>
    await statsApi.getTokenHodlerLeaderboard({ skip, limit }),

  getNftHodlerLeaderboard: async ({ skip, limit }) =>
    await statsApi.getNftHodlerLeaderboard({ skip, limit }),

  getNftVolumeLeaderboard: async ({ skip, limit }) =>
    await statsApi.getNftVolumeLeaderboard({ skip, limit }),

  getZealyLeaderboard: async ({ skip, limit }) =>
    await statsApi.getZealyLeaderboard({ skip, limit }),

  getPortfolioLeaderboard: async ({ skip, limit }) =>
    await statsApi.getPortfolioLeaderboard({ skip, limit }),

  getPowerTweetLeaderboard: async ({ skip, limit }) =>
    await statsApi.getPowerTweetLeaderboard({ skip, limit }),

  getListingLeaderboard: async ({ skip, limit }) =>
    await statsApi.getListingLeaderboard({ skip, limit }),
};
