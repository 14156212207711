import ReactGA from 'react-ga4';
import { openLink } from './openLink';
import { RP_X_SHARE_MESSAGE, POWER_TWEET_REWARD_MESSAGE } from 'constants';

const description = encodeURIComponent(RP_X_SHARE_MESSAGE);

export const socialMediaShare = (refCode, marketingTag = 'Marketing') => {
  ReactGA.event({
    category: 'webapp',
    action: marketingTag,
  });

  openLink(`https://x.com/intent/tweet?url=${description}${refCode}`);
};

export const powerTweetMediaShare = (refCode, data, marketingTag = 'Marketing') => {
  ReactGA.event({
    category: 'webapp',
    action: marketingTag,
  });

  const description = encodeURIComponent(POWER_TWEET_REWARD_MESSAGE(data));

  openLink(`https://x.com/intent/tweet?url=${description}${refCode}`);
};
